$(document).ready(function() {

    $(document).on('click', '#show-add-block', function(){
        $('.add-block').slideToggle();
    });

    $('#pos-ul, #wh-ul, #shipping-ul').nanoScroller();
});

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

// THIS is for all checkboxes
var array_ch_boxes = [];

$(document).on('change', 'input[type="checkbox"].upi-chk-box', function () {

    var el_val = $(this).val();

    if($(this).is(":checked")) {
        array_ch_boxes.push(el_val);
    }else{

        array_ch_boxes = $.grep(array_ch_boxes, function(value) {
            return value != el_val;
        });
    }

    var count_checked = array_ch_boxes.length;
    if (count_checked < 1) {
        count_checked = '';
    }
    $('span#display_checked_count').html(count_checked);
});


    $(document).on('submit', '#add-pos', function (e) {
        e.preventDefault();
        var list = $('#pos-ul ul'),
            form = $(this),
            scrollBlock = $('#pos-ul');

        $.post(form.attr('action'),
            form.serialize()).done(function (data) {
                list.html(data);
                form.find('input[type="text"]').val('');
                scrollBlock.nanoScroller({scrollTo: $('#pos-ul ul li:last-child')});
            });
    });

    $(document).on('submit', '#add-shipping', function (e) {
        e.preventDefault();
        var list = $('#shipping-ul ul'),
            form = $(this),
            scrollBlock = $('#shipping-ul');

        $.post(form.attr('action'),
            form.serialize()).done(function (data) {
                list.html(data);
                form.find('input[type="text"]').val('');
                //scrollBlock.nanoScroller({scrollTo: $('#shipping-ul ul li:last-child')});
            });
    });

    $(document).on('submit', '#save-pos', function (e) {
        e.preventDefault();
        var list = $('#pos-ul ul'),
            content = $('#load_content');

        $('#ajax-loader').show();

        $.post($(this).attr('action'),
            $(this).serialize()).done(function (data) {
                list.html(data);
                content.empty();
                $('#ajax-loader').hide();
            });
    });

    $(document).on('click', '.del-btn', function () {
        $("input[name='deletion_mark']").val('1');
    });

    $(document).on('change','.countQuantity',function(e) {
        e.preventDefault();
        var el = $(this);

        $.post(el.data('href')
            , {
                quantity:       el.val(),
                id:             el.data('id'),
                shipping_id:    el.data('shippingId')
            }, function (data) {
                if(el.data('back') == 'sh-link'){
                    $('#asside_menu li.active > span.sh-link').click();
                }

            });
    });

    $(document).on('change', 'input[name="all"]', function () {
        if ($(this).is(':checked')) {

            array_ch_boxes = [];
            $('input[type="checkbox"].upi-chk-box').each(function () {
                $(this).prop("checked", true);
                array_ch_boxes.push($(this).val());
            });
        } else {

            array_ch_boxes = [];
            $('input[type="checkbox"].upi-chk-box').prop('checked', false);
        }

        var count_checked = array_ch_boxes.length;
        if (count_checked < 1) {
            count_checked = '';
        }
        $('span#display_checked_count').html(count_checked);
    });

    $(document).on('click','.to-post',function(e) {
        e.preventDefault();
        var modal = $('#modalForm');
        //var itemsData = {};
        var el = $(this);

        /*$.each($('input[type="checkbox"]:checked.upi-chk-box'), function (i) {
            itemsData[i] = this.value;
        });*/
        if (array_ch_boxes.length) {
            $.post($(el).attr('href')
                , {
                    toPostData: array_ch_boxes
                }, function (data) {
                    // сначала показать форму
                    modal.modal('show');

                    // потом очистить содержимое
                    modal.find('.modal-body').html('');
                    modal.find('.modal-locked').html('');
                    modal.find('#modalDeleteButton').hide();
                    modal.find('#modalPostButton').hide();
                    modal.find('#modalSaveButton').show().removeAttr('disabled');
                    modal.find('.modal-title').html(data.modalTitle);

                    modal.find('.modal-body').html(data.content);
                });
        }
    });

    $(document).on('click', '.add-purchase', function (e) {
        e.preventDefault();
        var modal = $('#modalForm');
        //var itemsData = {};
        var el = $(this);

        /*$.each($('input[type="checkbox"]:checked.upi-chk-box'), function (i) {
            itemsData[i] = this.value;
        });*/
        if (array_ch_boxes.length) {

            $.post($(el).attr('href')
                , {
                    sku: array_ch_boxes
                }, function (data) {
                    // сначала показать форму
                    modal.modal('show');

                    // потом очистить содержимое
                    modal.find('.modal-body').html('');
                    modal.find('.modal-locked').html('');
                    modal.find('#modalDeleteButton').hide();
                    modal.find('#modalPostButton').hide();
                    modal.find('#modalSaveButton').show().removeAttr('disabled');

                    modal.find('.modal-body').html(data);
                });
        }
    });

    $(document).on('submit', '#add-wh', function (e) {
        e.preventDefault();
        var list = $('#wh-ul ul'),
            form = $(this),
            scrollBlock = $('#wh-ul');

        $.post(form.attr('action'),
            form.serialize()).done(function (data) {
                list.html(data);
                form.find('input[type="text"]').val('');
                scrollBlock.nanoScroller({scrollTo: $('#wh-ul ul li:last-child')});
            });
    });

    $(document).on('click', '#asside_menu li > .list-element', function (e) {
        var list = $('#asside_menu'),
            modal = $('#modalForm'),
            confirm_text = $(this).data('confirm');
        if (confirm(confirm_text)) {
            $.post($(this).data('url'))
                .done(function (data) {
                    if(data.result == "OK") {
                        list.html(data.content);

                    }else if(data.result == "WARNING"){
                        modal.modal('show');
                        modal.find('.modal-body').html('').html(data.content);
                        modal.find('.modal-locked').html('');
                        modal.find('.modal-title').html('').text(data.modalTitle);

                        modal.find('#modalDeleteButton').hide();
                        modal.find('#modalPostButton').hide();
                        modal.find('#modalSaveButton').hide();
                    }
                });
        }

    });

    $(document).on('submit', '#wh-edit', function (e) {
        e.preventDefault();
        $('#ajax-loader').show();
        var list = $('#wh-ul ul');

        $.post($(this).attr('action'),
            $(this).serialize())
            .done(function (data) {
                list.html(data);
                $('.warehousing_content').html('');
                $('#ajax-loader').hide();
            });
    });


    $(document).on('click', '#btn-sync-orders', function (e) {
        e.preventDefault();
        var el = $(this),
            link = $('#asside_menu li.active > span.pos-link'),
            modal = $('#modalForm'),
            textEl = el.html();

        el.html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');

        $.get(el.attr('href'))
            .done(function (data) {
                if(data.result == "OK") {
                    link.click();
                }else if(data.result == "WAITING"){

                    // сначала показать форму
                    modal.modal('show');

                    // потом очистить содержимое
                    modal.find('.modal-body').html('').text(data.text);
                    modal.find('.modal-locked').html('');
                    modal.find('.modal-title').html('');

                    modal.find('#modalDeleteButton').hide();
                    modal.find('#modalPostButton').hide();
                    modal.find('#modalSaveButton').hide();

                    el.html(textEl);
                }
            });

    });

$(document).on('click', '#skuFileLink, #upiFileLink', function (e) {
    $($(this).data('target')).click();
    e.preventDefault();
});

$(document).on('change', '#skuFile, #upiFile', function () {
    $($(this).data('form')).submit();
});

$(document).on('submit', '#skuFileForm, #upiFileForm', function (e) {
    e.preventDefault();
    var el = $(this);
    var formdata = new FormData(this),
        callback_link = el.data('callbackLink');

    $.ajax({
        async: true,
        type: "POST",
        dataType: "json",
        contentType: false,
        url: $(el).attr('action'),
        data: formdata,
        processData: false,
        success: function (data) {
            if (data.result == 'OK') {
                $('#asside_menu li.active > span.'+ callback_link +'-link').click();
            }
        }
    });
});

$(document).on('click', '#modalFormToggle, .modalFormToggle', function (e) {
    e.preventDefault();
    var modal = $('#modalForm');
    var el = $(this);

    $(modal).data('href', $(el).attr('href'));
    $(modal).data('noModalSubmit', $(el).hasClass('no-modal-submit'));
    $(modal).data('formName', '');

    reloadModal(modal);

    return false; // prevent other actions
});

$(document).on('change', 'input.autoCommit', function () {
    var data = {
        ownerId: $(this).data('ownerId'),
        field: $(this).attr('name'),
        value: $(this).val()
    };
    var element = $(this);
    element.removeClass('bg-success').removeClass('bg-warning');
    $.post($(this).data('action'), data, function (data) {
        if (data.status == 'OK') {
            element.addClass('bg-success');
            processModalResponse(data)
        } else {
            element.addClass('bg-warning');
        }
    });
});


function reloadModal(modal) {

    // сначала показать форму
    modal.modal('show');

    // потом очистить содержимое
    modal.find('.modal-body').html('');
    modal.find('.modal-locked').html('');
    modal.find('.modal-title').html('');

    // потом загрузить содержимое по ajax
    $.get($(modal).data('href'), function (data) {
        var content;

        // если ответ сервера JSON - разобрать его
        if (jQuery.type(data) === 'object') {

            content = data.content;

            // обработать переданные параметры формы
            // название формы
            $(modal).data('formName', data.formName);
            if(data.modalTitle !== 'undefined') {
                modal.find('.modal-title').html(data.modalTitle);
            }
            if (data.blockData) {
                modal.find('.modal-locked').html(data.blockTitle);
                $(modal).data('noModalSubmit', true);
                $(modal).data('noDeleteButton', true);
            }

        } else {
            content = data;
        }

        modal.find('.modal-body').html(content);
        onModalLoad(modal);
    });
}

function onModalLoad(modal) {
    $(modal).find('#modalDeleteButton').hide();
    $(modal).find('#modalPostButton').hide();
    $('#modalSaveButtonShippingToPurch').removeAttr('disabled');

    if ($(modal).data('noModalSubmit') || $(modal).find('form').hasClass('no-modal-submit')) {
        $(modal).find('#modalSaveButton').hide();
    } else {
        $(modal).find('#modalSaveButton').show().removeAttr('disabled');
    }

    if ($(modal).find('form').hasClass('show-post-button')) {
        $(modal).find('#modalPostButton').show().removeAttr('disabled');
    }
    if (!$(modal).data('noDeleteButton') && $(modal).find('form').hasClass('show-delete-button')) {
        $(modal).find('#modalDeleteButton').show().removeAttr('disabled');
    }
}

$(document).on('click', '#modalSaveButton', function () {
    submitModalForm();
});


$(document).on('click', '#modalPostButton', function () {
    $('input[name=posted]').val(true);
    submitModalForm();
});

$(document).on('click', '#modalDeleteButton', function () {
    if (confirm('Are you sure?')) {
        $('input[name=deletion_mark]').val(true);
        submitModalForm();
    }
});

$(document).on('keyup keypress','#modalForm form', function(e) {

    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
        e.preventDefault();
        return false;
    }
});

function submitModalForm() {

    beforeModalFormSubmit();

    var form = $('.modal-body').find('form');
    var modal = $('#modalForm'),
        errors_text = '';

    if ($(form).hasClass('optionFormSubmitNoAjaxClass')) {
        $(form).submit();
        return true;
    }

    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize(),
        complete: function (data) {
            processModalResponse(data);
        }
    }).fail(function (data) {

        if (data.status == 422) {

            var errors = data.responseJSON;
            //всем полям  статус ок
            $(modal).find('div.form-group').removeClass('has-error has-feedback');
            $(modal).find('div.form-group').addClass('has-success has-feedback');

            // удалить все окна об ошибках

            $('div.form-control-message').remove();

            $.each(errors, function (key, value) {
                $(modal).find('#' + key).parent('div.form-group').removeClass('has-success has-feedback');
                $(modal).find('#' + key).parent('div.form-group').addClass('has-error has-feedback');

                $(modal).find('#' + key).parent().parent('div.form-group').removeClass('has-success has-feedback');
                $(modal).find('#' + key).parent().parent('div.form-group').addClass('has-error has-feedback');

                errors_text = errors_text + value.toString() + "<br>";

                /*$(modal).find('#' + key).siblings('span.glyphicon-ok').remove();

                 $(modal).find('#' + key).parent().siblings('span.glyphicon-ok').remove();

                 $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>')
                 .appendTo($(modal).find('#' + key).parent('div.form-group'));
                 $('<div class="alert alert-danger form-control-message" role="alert"></div>')
                 .appendTo($(modal).find('#' + key).parent('div.form-group'));

                 $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>')
                 .appendTo($(modal).find('#' + key).parent().parent('div.form-group'));
                 $('<div class="alert alert-danger form-control-message" role="alert"></div>')
                 .appendTo(
                 $(modal).find('#' + key).parent().parent('div.form-group')
                 )
                 .append(value.toString());*/

            });

            $('<div class="alert alert-danger form-control-message" role="alert"></div>')
                .prependTo(
                $(modal).find('form')
            )
                .append(errors_text);

            afterModalFormSubmit();
        }
        else {
            console.log(data.errors);
        }
    });

}

function beforeModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').attr('disabled', 'disabled');
    $(modal).find('#modalPostButton').attr('disabled', 'disabled');
    $(modal).find('#modalDeleteButton').attr('disabled', 'disabled');
}

function afterModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').show().removeAttr('disabled');
    $(modal).find('#modalPostButton').removeAttr('disabled');
    $(modal).find('#modalDeleteButton').removeAttr('disabled');
}

function processModalResponse(data) {

    var isJson = true;
    if (data.responseText) {
        data = data.responseText;
    }
    if (jQuery.type(data) === 'string') {
        try {
            data = jQuery.parseJSON(data);
        }
        catch (err) {
            console.log(err);
            isJson = false;
        }
    }

    if (isJson) {

        if (data.status == 'fail') {
            return false;
        } else if (data.status == 'ERROR') {
            console.log(data.message);
            return false;
        }

        var modal = $('#modalForm');

        if (data.action == 'process_invoker_action' && data.owner_id) {

            var invoker = $('#modalFormToggle[data-owner-id="' + data.owner_id + '"]');
            $(invoker)
                .addClass('ajaxAction')
                .removeAttr('id')
                .attr('href', $(invoker).data('postAction'))
                .click();

        }
        if (data.action == 'reload_page') {

            window.location.reload();

        } else if (data.action == 'update_comment') {

            // FIXME make universal action
            updateComment(data.purch_id, data.comment);

        } else if (data.action == 'callback') {

            // выполнить пользовательскую функцию, расположенную в форме модального окна
            window["callbackModal"]();
        } else if (data.action == 'callback_static') {

            // выполнить пользовательскую функцию, расположенную в форме
            window[data.actionCallback](data.actionCallbackParams);
        }

        if (data.action == 'reload_modal') {

            if (data.reload_action) {
                $(modal).data('href', data.reload_action);
            }
            reloadModal($(modal));

        } else if (data.status == 'OK') {
            if ((modal.data('bs.modal') || {}).isShown) {
                $(modal).modal('hide');
            }
        }

        return (data.status == 'ok');
    }


}

function updateComment($purch_id, $comment) {

    // FIXME search element
    var el = 'a[href^= "purchasing/getCommentForm/' + $purch_id + '"]';
    if ($comment == null) {
        $comment = "";
    }
    $(el).text('Comment: ' + $comment);

}
$(document).on('click', '.delete-admin-btn', function () {
    var itemsData = {};
    var link_delete = $(this).data('ownerId');
    var item;
    $.each($('input.delete-box-admin[type=checkbox]:checked'), function (i) {
        itemsData[i] = this.value;
    });
    if($.isEmptyObject(itemsData) == false) {
        $.post(
            link_delete, {
                items: itemsData
            }, function (data) {
                $.each(itemsData, function (i) {
                    item='#item-'+itemsData[i];
                    $(item).remove();
                });
            });
    }

});
/*$(document).on('click','#set_ul li > a', function (e) {
    e.preventDefault();

    $('#ajax-loader').show();
    $('#set_ul li').removeClass('active');
    $(this).parent().addClass('active');
    $('#settings_content').html('').load($(this).attr('href'),function(){
        $('#ajax-loader').hide();
    });
});*/



$(document).on("click", "#refresh_bb", function() {

    var pos_id = $('#refresh_bb').data('posId');
    var url = $('#refresh_bb').data('refreshUrl');
    // поставить всем полям значок обновления
    $('.buy_box_price').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');
    $('.buy_box_name').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');

    //получить Json с новыми ценами и именнем продавца
    $.post(url, {pos_id: pos_id}, function (response) {
        if (response.status == 'OK') {
            var data  = JSON.parse(response.upis);


            $('.upi_table tbody tr').each(function (i) {
                var asin = $(this).data('asin');

                var filtereddata = data.filter(function(obj) {
                    return (obj.asin === asin);
                });

                $(this).find('.buy_box_price').html(filtereddata[0].buy_box_price);
                $(this).find('.buy_box_name').html(filtereddata[0].name);
            });

        }
    });
});

$(document).on("click", ".refresh_bb_single", function(e) {
    e.preventDefault();
    var obj = $(this);
    var url = $(this).attr('href');
    var parent = $(obj).closest('tr');
    $(parent).find('.buy_box_price').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');
    $(parent).find('.buy_box_name').html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');

    $.get(url, function (response) {
        if (response.status == 'OK') {
            $(parent).find('.buy_box_price').html(response.price);
            $(parent).find('.buy_box_name').html(response.name);


        }
    });

});

$(document).on('click', '.postUpiLink', function(e){
    e.preventDefault();
    var el = $(this);

    $.post(el.attr('href'))
        .done(function(){

            $('#asside_menu li.active > span.pos-link').click();
        });
});

$(document).on('change', '#list_wh', function(e){
    e.preventDefault();
    var el = $(this),
        ipuWh = $('#skuInUpi').data('wh');

    // for sku tu UPI
    if(el.val() != ipuWh)
    {
        $('#skuInUpi').hide()
        $('#skuInUpi .sku-chk-box').attr('checked', false);
        $('#skuInUpi .sku-chk-box').prop("checked", false);

    }else{
        $('#skuInUpi').show()
        $('#skuInUpi .sku-chk-box').attr('checked', true);
        $('#skuInUpi .sku-chk-box').prop("checked", true);
    }

    $('input[name="wh_id"]').attr('value' , el.val());
    // end for sku tu UPI

    $.post(el.data('url'),{
        wh:el.val()

    })
        .done(function(data){
            $('#sku_list').html(data);
        });
});

$(document).on('click','.action-many',function(e) {
    e.preventDefault();
    //var itemsData = {};
    var el = $(this);

    /*$.each($('input[type="checkbox"]:checked.upi-chk-box'), function (i) {
        itemsData[i] = this.value;
    });*/
    if (array_ch_boxes.length) {
        $.post($(el).attr('href')
            , {
                list: array_ch_boxes
            }, function (data) {
                if(el.data('back') == 'sh-link'){
                    $('#asside_menu li.active > span.sh-link').click();
                }else{
                    $('#asside_menu li.active > span.pos-link').click();
                }
            });
    }
});
$(document).on('click','.upi-to-purchase',function(e) {
    e.preventDefault();
    var modal = $('#modalForm');
    //var itemsData = {};
    var el = $(this);

    /*$.each($('input[type="checkbox"]:checked.upi-chk-box'), function (i) {
        itemsData[i] = this.value;
    });*/
     if (array_ch_boxes.length) {

        $.post($(el).attr('href')
            , {
                upi: array_ch_boxes
            }, function (data) {
                // сначала показать форму
                modal.modal('show');

                // потом очистить содержимое
                modal.find('.modal-body').html('');
                modal.find('.modal-locked').html('');
                modal.find('#modalDeleteButton').hide();
                modal.find('#modalPostButton').hide();
                modal.find('#modalSaveButton').show().removeAttr('disabled');
                modal.find('.modal-title').html(data.modalTitle);

                modal.find('.modal-body').html(data.content);
            });
    }
});

$(document).on('click','.purchase-set-many',function(e) {
    e.preventDefault();
    var itemsData = {};
    var el = $(this);

    if ($(el).data('confirmation')) {
        if (!confirm($(el).data('confirmation'))) {
            return false;
        }
    }

    $.each($('input[type="checkbox"]:checked.upi-chk-box'), function (i) {
        var a = $('a.' + el.data('targetClass') + '[data-owner-id="' + this.value + '"]');
        if(!a.hasClass('disabled')){
            a.attr('data-confirmation', null).click();
        }

    });

});

$(document).on('change', '.change_check_made', function (e) {
    var el = $(this);
    var id = el.data('ownerId'),
        url = el.data('url'),
        button = $('#check-' + id),
        modalSaveBtn = $('#modalSaveWhPurchaseButton'),
        we_check = $('.we_check[data-owner-id="' + id +'"]').prop("checked") == true ? 1 : 0,
        ch_check = $('.ch_check[data-owner-id="' + id +'"]').prop("checked") == true ? 1 : 0;

    $.post(url, {
        we_check: we_check,
        ch_check: ch_check
    }, function (response) {
        if (response.status == 'OK') {
            button.addClass('ajaxAction').removeClass('disabled');
            modalSaveBtn.removeClass('hidden');
        }
        else {
            modalSaveBtn.addClass('hidden');
            button.removeClass('ajaxAction').addClass('disabled');
        }
    });
    return false;
});


$(document).on('click', '.tableRowToggler', function (e) {
    var owner_id = $(this).data('owner');
    var container = $('.sub-table[data-owner=' + owner_id + ']');
    $(container).toggleClass('hidden');

    if ($(container).hasClass('load-remote') && !$(container).hasClass('hidden')) {
        var status = $('input[name="statuses"]').val();
        var currentRoute = $('input[name="currentRoute"]').val();
        $.post($('input[name="table-expand-action"]').val(), {
            status: status,
            manager_id: owner_id,
            currentRoute: currentRoute
        }, function (response) {
            $(container).children('td.no-padding').html(response);
        });
    }
});

$(document).on('click', '.ajaxAction', function (e) {
    e.preventDefault();
    var el = $(this);

    if (el.is('a')) {

        if ($(el).data('confirmation')) {
            if (!confirm($(el).data('confirmation'))) {
                return false;
            }
        }

        var elContent = $(el).html();

        $(el).html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>');

        var ownerId = $(el).data('ownerId');
        var someData = [];
        var objects = $('.data-container[data-owner-id="' + ownerId + '"] .data-ajax-post').toArray();
        var requiredError = false;

        if (!$(el).hasClass('no-requires')) {
            for (var i = 0; i < objects.length; i++) {
                someData.push([$(objects[i]).attr('name'), $(objects[i]).val()]);

                var value = $(objects[i]).val();
                if ($(objects[i]).hasClass('required') && (!value || value == 0)) {
                    $(objects[i]).tooltip({
                        placement: 'top',
                        trigger: 'manual',
                        title: 'Field required'
                    }).tooltip('show');
                    requiredError = true;
                }
            }
        }

        if (requiredError) {
            $(el).html(elContent).addClass('btn-warning');
            return false;
        }
        $.post($(el).attr('href'), {
            ownerId: ownerId,
            'someData': someData
        }, function (data) {
            $(el).html(elContent);
            if (data == 'remove_owner_table_row') {
                $('tr[data-owner-id="' + ownerId + '"]').addClass('bg-warning').fadeOut( "medium", function() {
                    $(this).remove();

                    setTimeout(function() {
                        var count_checked = $('input[type=checkbox]:checked.upi-chk-box').length;
                        if (count_checked < 1) {
                            count_checked = '';
                        }
                        $('span#display_checked_count').html(count_checked);
                    }, 10);
                });

                getMenuVal();
            } else if (data == 'false') {
                console.log('falls');
                $(el).addClass('btn-warning');
            } else if (data.status == 'ERROR') {
                $(el).addClass('btn-warning');
            } else if (data.action == 'callback_static') {
                // выполнить пользовательскую функцию, расположенную в форме
                window[data.actionCallback](data.actionCallbackParams);
            } else if (data == 'callback') {
                window[$(el).data('callback')](el);
            } else if (data.action == 'reload_modal') {
                var modal = $('#modalForm');
                if (data.reload_action) {
                    $(modal).data('href', data.reload_action);
                }
                reloadModal($(modal));
            } else if (data.action == 'reload_page') {
                window.location.reload();
            }else{
                console.log(data);
            }
        });

    }
});


$(document).on('change', '.not-greater', function () {
    var defaultValue = parseInt($(this).prop('defaultValue'));
    if (defaultValue < parseInt($(this).val())) {

        if (confirm($(this).data('confirmationText'))) {
            $('.ajaxAction[data-owner-id="' + $(this).data('ownerId') + '"][href="' + $(this).data('confirmationAction') + '"]').click();
        } else {
            $(this).val(defaultValue).addClass('bg-warning');
        }
    } else {
        $(this).removeClass('bg-warning');
    }
});


function getMenuVal(){
    var url = $('input[name="menu-val-update"]').val();

    $.post(url).done(function(data){

        $.each(data, function (i) {
            $('#' + i).text(data[i]);
        });

    });
}


// scheduler controls
$(document).on('click', '.schedule-item-link', function (e) {
    e.preventDefault();
    $('.schedule-item-form-container').load($(this).attr('href'), function (data) {
        var jobLogContainer = $('#scheduler-job-log-container');
        $(jobLogContainer).load($(jobLogContainer).data('action'), function (data) {
        });
    });
});

$(document).on('click', '#scheduler-job-log-container>ul.pagination>li>a', function (e) {
    e.preventDefault();
    var jobLogContainer = $('#scheduler-job-log-container');
    $(jobLogContainer).load($(this).attr('href'), function (data) {
    });
});
// scheduler controls

$(document).on('click', '.system-error-page ul.pagination>li>a', function (e) {
    e.preventDefault();
    var jobLogContainer = $('#load_content');
    $(jobLogContainer).load($(this).attr('href'), function (data) {
    });
});

$(document).on('click','#asside_menu > li > span:not(.delete-ajax-item)', function (e) {
    e.preventDefault();
    $('#ajax-loader').show();
    $('#asside_menu > li').removeClass('active');
    $(this).parent().addClass('active');
    $('#load_content').html('').load($(this).data('href'), function(response, status, xhr) {
        if (status == "error") {
            $(this).html(response);
        }
        $('#ajax-loader').hide();

    });
    // set empty array of
    array_ch_boxes = [];

    $('.swipe_block').css({'right' : '100%'})
});

function loadContentByLink(link) {
    $('#ajax-loader').show();
    $('#load_content').html('').load(link, function(response, status, xhr) {
        if (status == "error") {
            $(this).html(response);
        }
        $('#ajax-loader').hide();

    });
    // set empty array of
    array_ch_boxes = [];

    $('.swipe_block').css({'right' : '100%'});
}

$(document).on('click', '#paginate_list ul.pagination>li>a', function (e) {
    e.preventDefault();
    var jobLogContainer = $('#load_content');
    $('#ajax-loader').show();

    jobLogContainer.html('').load($(this).attr('href'), function(response, status, xhr) {
        if (status == "error") {
            $(this).html(response);
        }
        $('#ajax-loader').hide();
    });
});

$(document).on('shown.bs.modal', '#modalForm', function (e) {
    if($('.images_block').length){
        $('.images_block').resize();
    }
});


$(document).on('click', '#sidebar-wrapper', function (e) {
    $('#modalForm').modal('hide');
});

$(document).on('click', '#sku_purchase_images .upi-image-preview-block', function(){
    var img = $(this).find('img').attr('src'),
        fullImg = $('#purchase-photo-preview img');

    fullImg.attr('src', img);
});

function checkPagination()
{
    var pagination = $('.pagination');

    if(pagination.children().length > 1){
        pagination.show();
    }else{
        pagination.hide();
    }
}